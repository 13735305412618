import React from 'react'

function Photos() {
    
    return (
        <div>
            <h2>Photos</h2>
            
            <img alt="Cutie" src={require('/Users/simonfarruqui/Desktop/Computer Science/simonfarruqui.com-master/src/assets/sf1.png')} />
            <img alt="Coolboi" src={require('/Users/simonfarruqui/Desktop/Computer Science/simonfarruqui.com-master/src/assets/sf1.png')} />
        </div>
    )
}

export default Photos